import { Helmet } from 'react-helmet'

import * as React from 'react'
import Layout from '../components/Layout'

import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import FAQs from '../components/FAQs'

import { Box, Flex } from 'grid-styled'
import CTA from '../components/CTA'
import GalleryContainer from '../components/GalleryContainer'
import GalleryImage from '../components/GalleryImage'
import {
  HomeHeader,
  FormContainer,
  AlternatingContent,
  AboutContact,
  VideoContainer,
  info
} from '.'
import Form from '../components/Form'
import { Text, GallerySection, Content } from '../components/styles'
import Button from '../components/Button'
import ContentCard from '../components/ContentCard'
import Hero from '../components/Hero'
import { Video } from '../components/procedures'

export default ({ data }) => (
  <Layout header="light">
    <Helmet>
      <title>
        Patio Roofing in Brisbane & The Sunshine Coast | Just Patios
      </title>
      <meta
        name="description"
        content="Just Patios are the trusted patio roofing contractors in Brisbane & the Sunshine Coast. We install a number of roofing systems including Ausdeck. Call us today for a free quote."
      />
    </Helmet>

    <HomeHeader slant={false} height="75vh">
      <figure>
        <Img fluid={data.jpBackground.childImageSharp.fluid} />
      </figure>
      <div>
        <h2>Patio Roofing Contractors</h2>
        <Text size="large">
          Looking at patio roofing solutions for your home? If you want some
          help our team at Just Patios is ready to handle any question.
        </Text>
        <Link to="/contact-us/">
          <Button>Contact us today</Button>
        </Link>
      </div>
      <FormContainer>
        <div>
          <Form title="Request Free Quote" showMessage={true} />
        </div>
      </FormContainer>
    </HomeHeader>

    <Content style={{ paddingTop: 100 }}>
      <h1>
        Patio Roofing Contractors in Brisbane <i>&amp;</i> The Sunshine Coast
      </h1>

      <p>
        At Just Patios we provide a diverse range of Patio Roofing solutions to
        meet your needs. Whether you need insulated or single skin roofing
        options, our sales representatives will help design and create your
        ideal structure that is engineered and built to last for many years. Our
        patio systems offer a full range of colours to enable your structure to
        blend in with your home. These structures are streamline and modern
        looking making it a great asset to your lifestyle.
      </p>

      <p>
        Patio roofing systems need to withstand harsh weather conditions. Rain
        or shine, your space must be kept comfortable and be protected from
        leaks and other damage caused by prolonged exposure to natural elements.
        Because of this, homeowners like you can’t opt for just any roofing
        material for your outdoor living space.
      </p>

      <p>
        Here at Just Patios in Brisbane, we offer insulated patio and
        non-insulated patio roofing systems. Affordable and easy to install, our
        roofing options suit a variety of applications; corrugated iron roofing
        is ideal for carports, while insulated roofing materials are suitable
        for living spaces.
      </p>

      <p>
        If you need help to select the right roofing system for your patio
        living space or carport, Just Patio’s team of professional team members
        can give you good advice.
      </p>

      <p>
        With over a decade of experience in providing the Sunshine Coast &
        Brisbane with roofing systems, we offer you high-quality solutions and
        excellent workmanship. Trust the professional contractors at Just Patios
        to deliver quality roofing systems for various applications.
      </p>

      <p>
        View our range of designs below, and feel free to get in touch for a
        free quote and design consultation.
      </p>

      <Link to="/contact-us/">
        <Button inverted={true}>Contact us today!</Button>
      </Link>
    </Content>

    <AlternatingContent>
      <h2>
        Our <i>Roofing</i> Range
      </h2>
      <ContentCard
        image={data.accessories.childImageSharp}
        title="Ausdeck V-line"
        text="The Ausdeck V-line is built for entertainment. It can span up to 5.1 metres, reducing the use of batterns and rafters thus allowing for a wide roofed-outdoor living area that uses the least number of posts. With such a smart design, the roof is as strong as it is beautiful.

        The Ausdeck V-line has a full high gloss roof finish with a VJ underside profile. This is manufactured in high tensile steel and available in a range of colours. You can choose from Thredbo White, Smooth Cream, Merino, Slate Grey/Merino, Heritage Red/Smooth Cream & Monolith/Off White. With a range of options available, it’s easy to find a roofing system that easily blends with the colour and profile of your existing roof.
        "
      />
      <ContentCard
        image={data.training.childImageSharp}
        title="Insulated Roofing"
        text="Just Patios brings quality insulated roofing systems to Brisbane and the Sunshine Coast. These system’s insulated roofing panels are designed to be installed on any kind of suitable frame. They are available in 50mm, 75mm and 100mm thickness and have an R-Value of up to 1.61 on 50mm, up to 2.26 on 75mm and up to 2.66 on 100mm. With this roofing system’s insulating capabilities, homeowners can enjoy lower heating and cooling costs in their new outdoor living area. Our expert builders will make sure your beautiful roofing is built to last."
      />
      {/* <ContentCard
        image={data.offField.childImageSharp}
        title="Solarspan Roofing"
        text="SolarSpan roofing is one of Australia’s best insulated roofing systems. Our clients in the Sunshine Coast and Brisbane have enjoyed their insulated roof panels for years. This insulated roofing is ideal for use on any existing frame either timber or steel. Providing a ceiling, insulation and roofing sheet in one product, the SolarSpan roofing is the all-in-one insulated roofing solution for your outdoor extensions."
      /> */}
      <ContentCard
        image={data.patiosBrisbane.childImageSharp}
        title="Corrugated Iron (CGI) Roofing"
        text="Corrugated Roofing is ideal for pitched roofs to remove water quickly. It is highly durable and resistant to adverse weather conditions and is used in our patio and carport roof applications. With its lightweight nature and impressive durability and flexibility, corrugated roofing is a favourite amongst builders and homeowners and comes in all Colourbond and Colourbond equivalent colours. Having clean lines, CGI roofing gives properties a clean modern look with a timeless appeal."
      />
    </AlternatingContent>

    <Hero bgImage={data.blurredHero.childImageSharp} height="550px">
      <h3>Want to find our more?</h3>
      <p>
        Call 1300 284 531 or complete the enquiry form to contact us today and
        find out how we can help.
      </p>
      <Flex>
        <Button color={'#fff'} small={true} outline={true}>
          Contact Us
        </Button>
      </Flex>
    </Hero>

    <GallerySection>
      <h2>Some of Our Recent Projects</h2>
      <p>
        Here at Just Patios, we do more than just build patios. We create
        structures that fit your lifestyle. If you require a patio for your
        home, our professional and licensed builders ensure high-quality results
        that enhance the value of your property. Our team build insulated and
        non-insulated patios, completely customising each job to meet your
        unique home needs.
      </p>
      <p>
        We’ve hand-selected the best systems in Australia and use quality
        products backed with 15-year warranties. View photos from some of our
        most recent projects and discover for yourself what we can achieve for
        you.
      </p>
      <GalleryContainer>
        {data.gallery.edges.map(
          (image, i) =>
            image.node.childImageSharp && (
              <GalleryImage modal={image.node.childImageSharp.modal}>
                <Img fixed={image.node.childImageSharp.fixed} />
              </GalleryImage>
            )
        )}
      </GalleryContainer>
    </GallerySection>

    <FAQs
      flushTop={true}
      faqs={info}
      title={
        <>
          What sets <i>us</i> apart?
        </>
      }
      background={data.whyUs.childImageSharp.fluid.src}
    />

    <VideoContainer>
      <Video
        videoId="EVtDJ0iNRtI"
        opts={{
          height: '576',
          width: '1024',
          playerVars: {
            color: 'white',
            rel: 0
          }
        }}
      />
    </VideoContainer>

    <AboutContact style={{ margin: '0 auto', padding: 0 }} />
  </Layout>
)

export const query = graphql`
  query PatioRoofingQuery {
    jpBackground: file(relativePath: { eq: "patio-roofing-brisbane.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    offField: file(relativePath: { eq: "gallery/roof/solarspan.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    whyUs: file(relativePath: { eq: "why-us-jp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    patiosBrisbane: file(relativePath: { eq: "gallery/roof/cgi.png" }) {
      childImageSharp {
        fluid(toFormat: JPG) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    training: file(relativePath: { eq: "gallery/roof/insulated.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    accessories: file(relativePath: { eq: "gallery/roof/iron.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blurredHero: file(relativePath: { eq: "blurred-hero.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    gallery: allFile(filter: { relativeDirectory: { eq: "gallery/patios" } }) {
      edges {
        node {
          childImageSharp {
            fixed(width: 400, height: 400, toFormat: JPG) {
              ...GatsbyImageSharpFixed
            }
            modal: fluid(maxWidth: 1200, quality: 98, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
